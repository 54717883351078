.intro {
  background-color: white;
  display: flex;

  .left {
    flex: 0.5;
    overflow: hidden;

    .imgContainer {
      width: 700px;
      height: 700px;
      // background-color: crimson;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      float: right;

      img {
        height: 90%;
      }
    }
  }
  .right {
    flex: 0.5;
    position: relative;

    .wrapper {
      width: 100%;
      height: 100%;
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h1 {
        font-size: 60px;
        margin: 10px 0;
      }
      h2 {
        font-size: 35px;
      }
      h3 {
        font-size: 30px;

        span {
          font: inherit;
          color: crimson;
        }

        .ityped-cursor {
          animation: blink 1s infinite;
        }

        @keyframes blink {
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
      }
    }
    a {
      position: absolute;
      bottom: 10px;
      left: 40%;

      img {
        width: 30px;
        animation: arrowBlink 2s infinite;
      }
      @keyframes arrowBlink {
        100% {
          opacity: 0;
        }
      }
    }
  }
}
